import React, { Dispatch, SetStateAction } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useAuth } from "@akj-dev/affinity-platform";
import { useSaleStatuses } from "../hooks";
import { SaleStatusEnum } from "./enums";

export interface SaleStatusSelectorProps {
  saleStatusSelected: SaleStatusEnum;
  setSaleStatusSelected: Dispatch<SetStateAction<SaleStatusEnum>>;
}

export const SaleStatusSelector = ({
  saleStatusSelected,
  setSaleStatusSelected,
}: SaleStatusSelectorProps) => {
  const { root } = useStyles();
  const accountSettings = useAuth().accountSettings;
  const saleStatuses = useSaleStatuses(accountSettings);

  const handleChange = (event: SelectChangeEvent<SaleStatusEnum>) => {
    const newSaleType = event.target.value as SaleStatusEnum;
    setSaleStatusSelected(newSaleType);
  };

  return (
    <Select
      classes={{ select: root }}
      variant="outlined"
      value={saleStatusSelected}
      onChange={handleChange}
    >
      {saleStatuses.map(({ id, label }) => (
        <MenuItem key={id} value={id}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

const useStyles = makeStyles({
  root: {
    minWidth: "200px",
  },
});
