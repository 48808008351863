import { QuotesResult, QuoteStatus } from "../../../api/v1/affinity/sales";
import { SalesStatusTableProps } from "../../containers/SalesStatusTable";
import { QuoteIdLink } from "./QuoteIdLink";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../utils/formatCurrency";
import { PDFDownload } from "./PDFDownload";
import { DeleteButton } from "./DeleteButton";
import React from "react";
import { format, fromUnixTime } from "date-fns";
import { QUOTE_STATUS_NAMES } from "../../containers/SalesStatusTable/constants";
import { ContractButton } from "./ContractButton";

/**
 * Apply cell / column specific markup to result elements
 * @param result
 * @param columns
 * @param handleDelete
 * @param handleContractModal
 */
export const applyCellMarkup = (
  result: QuotesResult,
  columns: SalesStatusTableProps["columns"],
  handleDelete: (id: string) => void,
  handleContractModal: (id: string, name: string) => void
) =>
  columns.map((col) => {
    switch (col) {
      case "status":
        return QUOTE_STATUS_NAMES[result[col]];
      case "quoteId":
        if (
          result.status === QuoteStatus.draft ||
          result.status === QuoteStatus.approval ||
          result.status === QuoteStatus.internal_approval ||
          result.status === QuoteStatus.sent ||
          result.status === QuoteStatus.viewed
        )
          return (
            <QuoteIdLink
              to={`/sales/new/cpq?order_id=${result[col]}`}
              quoteId={result[col]}
              title="Launch sales wizard"
            />
          );
        if (result.status === QuoteStatus.completed)
          return (
            <QuoteIdLink
              href={getPathForCrossAppRedirect(
                AppSubdomains.ORDERS,
                `/orders/status?view=detail&id=${result[col]}`
              )}
              quoteId={result[col]}
              title="Launch orders"
            />
          );
        return result[col];
      case "opportunityCount":
        return (
          <Link
            to={`/sales/sales-status?status=draft&endCustomerAccountNo=${result.quoteId}`}
          >
            {result[col]}
          </Link>
        );
      case "createdAtTimestamp":
        return format(fromUnixTime(result[col]), "dd/MM/yyyy HH:mm");
      case "oneOffPrice":
        return formatCurrency(result[col]);
      case "recurringPrice":
        return formatCurrency(result[col]);
      case "pdfId":
        return <PDFDownload pdfId={result[col]} filename={result.quoteId} />;
      case "contract":
        if (
          result.status === QuoteStatus.sent ||
          result.status === QuoteStatus.viewed
        )
          return (
            <ContractButton
              onClick={() =>
                handleContractModal(result.quoteId, result.opportunityName)
              }
            />
          );
        return null;

      case "delete":
        return (
          <DeleteButton
            onClick={() => handleDelete(result.quoteId)}
            disabled={
              result.status === QuoteStatus.completed ||
              result.status === QuoteStatus.cancelled ||
              result.status === QuoteStatus.expired
            }
          />
        );
      default:
        return result[col];
    }
  });
