import React from "react";
import { useQuery } from "react-query";
import * as AccountsAPI from "../../api/v1/accounts";
import { SalesStatusPage } from "../../shared/components/SalesStatusPage";
import { SalesStatusTable } from "../../shared/containers/SalesStatusTable";
import { CountCards } from "./CountCards";

export const SalesStatusScreen = () => {
  const { data, isLoading } = useQuery(
    "accountSettings",
    async () => await AccountsAPI.AccountSettings()
  );

  return (
    <SalesStatusPage
      title="Sales Status"
      subtitle="View and track the status of all your sales"
    >
      <CountCards />
      <SalesStatusTable
        loadingSettings={isLoading}
        columns={[
          "quoteId",
          "status",
          "opportunityName",
          "companyName",
          "endCustomerAccountNo",
          "createdAtTimestamp",
          "createdByName",
          "oneOffPrice",
          "recurringPrice",
          "pdfId",
          ...((data?.can_access_vf_direct === "1"
            ? ["contract"]
            : [""]) as "contract"[]),
          "delete",
        ]}
      />
    </SalesStatusPage>
  );
};
