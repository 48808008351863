import React, { useMemo, useState } from "react";
import * as SalesAPI from "../../../api/v1/affinity/sales";
import {
  QuotesResponse200,
  QuotesResult,
  QuoteStatus,
  SortFields,
  SortOrder,
} from "../../../api/v1/affinity/sales";
import {
  DataTable,
  DataTableColumn,
  DataTableSortState,
} from "@akj-dev/shared-components";
import { useInfiniteQuery, useQueryClient } from "react-query";
import {
  COLUMN_FILTER_TYPES,
  COLUMN_NAMES,
  QUOTE_STATUS_NAMES,
} from "./constants";
import { DeleteDraftModal } from "../../components/DeleteDraftModal";
import { applyCellMarkup } from "../../components/SalesStatus/applyCellMarkup";
import { FiltersCard } from "@akj-dev/shared-components";
import { useSalesStatusFilterValues } from "../../hooks/useSalesStatusFilters";
import { useLocation } from "react-router-dom";
import { getUnixTime, parse } from "date-fns";
import { DATE_PATTERN } from "@akj-dev/shared-components";
import { CSVDownload } from "./CSVDownload";
import { Box } from "@mui/material";
import { ContractModal } from "../../components/ContractModal";

export interface SalesStatusTableProps {
  quoteStatus?: QuoteStatus;
  loadingSettings?: boolean;
  columns: (keyof QuotesResult | "contract" | "delete")[];
}

export const SalesStatusTable = ({
  quoteStatus,
  columns,
  loadingSettings,
}: SalesStatusTableProps) => {
  const dtColumns: DataTableColumn[] = useMemo(
    () =>
      columns.map((col) => ({
        label: COLUMN_NAMES[col],
        name: col,
        sortable: SortFields.hasOwnProperty(col),
      })),
    [columns]
  );
  const filterConfig = useMemo(
    () => ({
      data: columns
        .filter((col) => COLUMN_FILTER_TYPES.hasOwnProperty(col))
        .map((col) => {
          return {
            id: col,
            name: COLUMN_NAMES[col],
            type: COLUMN_FILTER_TYPES[col],
            resources: `${col}${quoteStatus ? `?status=${quoteStatus}` : ``}`,
            values: [],
            options: {},
            showToggle: true,
            valueDisplayFunc:
              col === "status"
                ? (v: string) => {
                    return v
                      .split(",")
                      .map(
                        (v) =>
                          QUOTE_STATUS_NAMES[
                            v as keyof typeof QUOTE_STATUS_NAMES
                          ] || v
                      )
                      .join(",");
                  }
                : undefined,
          };
        }),
    }),
    [columns, quoteStatus]
  );

  // URL Search params

  const { search } = useLocation();
  const filterParams = useMemo(() => {
    const params = new URLSearchParams(search);

    let filterParams: any = {};
    if (quoteStatus) filterParams.status = quoteStatus;
    for (const p of params.entries()) {
      if (p[0] === "createdAtTimestamp") {
        // This is a special case as it's a range selection that needs two separate params sending
        const fromDate = parse(p[1].split(",")[0], DATE_PATTERN, new Date());
        const toDate = parse(p[1].split(",")[1], DATE_PATTERN, new Date());
        filterParams.createdAtTimestamp_gte = getUnixTime(fromDate);
        filterParams.createdAtTimestamp_lte = getUnixTime(toDate);
      } else {
        if (columns.includes(p[0] as any)) filterParams[p[0]] = p[1];
      }
    }

    return filterParams;
  }, [search, columns, quoteStatus]);

  // Table state

  const initialSort = {
    sort: SortFields.createdAtTimestamp,
    order: SortOrder.desc,
  };
  const [sortState, setSortState] = useState(initialSort);
  const handleChangeSort = ({ columnIndex, dir }: DataTableSortState) => {
    if (!dir) {
      setSortState(initialSort);
    } else {
      setSortState({
        sort: dtColumns[columnIndex].name as SortFields,
        order: dir === "ASC" ? SortOrder.asc : SortOrder.desc,
      });
    }
  };

  // Data fetching
  const queryClient = useQueryClient();
  const { data, isLoading, isRefetching, error, fetchNextPage, hasNextPage } =
    useInfiniteQuery<QuotesResponse200, Error>(
      ["prospects", quoteStatus, sortState.sort, sortState.order, filterParams],
      ({ pageParam }) => {
        return SalesAPI.quotes({
          _page: pageParam || 1,
          _limit: 15,
          _sort: sortState.sort,
          _order: sortState.order,
          order_source: "Affinity Sales",
          ...filterParams,
        });
      },
      {
        getNextPageParam: (lastPage) => {
          const { meta } = lastPage;
          if (meta.page >= meta.totalPages) return false;
          return meta.page + 1;
        },
      }
    );
  const results = data?.pages.flatMap((page) => page.results) || [];

  // Delete modal
  const [deleteModalId, setDeleteModalId] = useState("");
  const handleDelete = (id: string) => {
    setDeleteModalId(id);
  };
  const handleDeleteModalClose = (reason: string) => {
    setDeleteModalId("");
    if (reason === "success") {
      queryClient.invalidateQueries(["prospects", quoteStatus]);
    }
  };

  // Contract reminder
  const [contractModalId, setContractModalId] = useState("");
  const [oppName, setContractName] = useState("");
  const handleContractModal = (orderId: string, oppName: string) => {
    setContractModalId(orderId);
    setContractName(oppName);
  };
  const handleContractModalClose = () => {
    setContractModalId("");
    setContractName("");
  };

  // Table data transform
  let displayData = results.map((result, i) =>
    applyCellMarkup(result, columns, handleDelete, handleContractModal)
  );

  return (
    <>
      <FiltersCard
        fetchHook={() => filterConfig}
        fetchResourcesHook={useSalesStatusFilterValues}
        title="Filter"
        hasReset={true}
        hasDownload={false}
        usage="salesStatus"
      />
      <Box mb={1} display="flex" justifyContent="flex-end">
        <CSVDownload filterParams={filterParams} />
      </Box>
      <DataTable
        columns={dtColumns}
        data={displayData}
        loading={isLoading || isRefetching || loadingSettings}
        error={error?.message}
        count={data?.pages[0].meta.totalCount}
        onChangeSort={handleChangeSort}
        onLoadMore={hasNextPage ? () => fetchNextPage() : undefined}
      />
      <DeleteDraftModal
        open={Boolean(deleteModalId)}
        orderId={deleteModalId}
        onClose={handleDeleteModalClose}
      />
      <ContractModal
        open={Boolean(contractModalId)}
        oppName={oppName}
        orderId={contractModalId}
        onClose={handleContractModalClose}
      />
    </>
  );
};
