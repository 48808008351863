import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface SalesStatusPageProps {
  title: string;
  subtitle: string;
  children: ReactNode;
}

export const SalesStatusPage = ({
  title,
  subtitle,
  children,
}: SalesStatusPageProps) => {
  return (
    <Box pt={1}>
      <Box mb={2}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Box>
      {children}
    </Box>
  );
};
