import React from "react";
import { SalesStatusPage } from "../../shared/components/SalesStatusPage";
import { QuoteStatus } from "../../api/v1/affinity/sales";
import { SalesStatusTable } from "../../shared/containers/SalesStatusTable";

export const ProspectsScreen = () => {
  return (
    <SalesStatusPage
      title="Prospects"
      subtitle="A complete list of your prospective customers"
    >
      <SalesStatusTable
        quoteStatus={QuoteStatus.lead}
        columns={[
          "quoteId",
          "customerEmail",
          "companyName",
          "customerName",
          "opportunityCount",
          "createdAtTimestamp",
          "createdByName",
        ]}
      />
    </SalesStatusPage>
  );
};
