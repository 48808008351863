import React, { useState } from "react";
import { startOfDay, subDays } from "date-fns";
import { Box, Grid, Typography } from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";

import { useFetchDashboardData, useHandleFromTo } from "./hooks";
import { SaleStatusEnum } from "./sale-status/enums";
import { SaleStatusSelector } from "./sale-status/SaleStatusSelector";
import { DateRangeEnum } from "./date-range/enums";
import { DateRangeSelector } from "./date-range/DateRangeSelector";
import { DateRangeCustomModal } from "./date-range/DateRangeCustomModal";
import { Chart } from "./chart/Chart";
import { PriceCard } from "./price-card/PriceCard";

export const Dashboard = () => {
  const now = new Date();
  const weekAgo = startOfDay(subDays(now, 7));

  const dateRangeCustomRef = React.useRef<HTMLButtonElement>(null);

  const [saleStatusSelected, setSaleStatusSelected] = useState(
    SaleStatusEnum.COMPLETED
  );
  const [dateRangeSelected, setDateRangeSelected] = useState(
    DateRangeEnum.WEEK
  );
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState<Date>(weekAgo);
  const [to, setTo] = useState<Date>(now);

  // custom useEffect that sets the from - to based on the dateRange
  useHandleFromTo(dateRangeSelected, setFrom, setTo);

  const { fetching, data, error } = useFetchDashboardData({
    dateRangeSelected,
    saleStatusSelected,
    from,
    to,
  });

  const { chartData, avgOneOffPrice, avgRecurringPrice } = data;

  return (
    <Box
      height="calc(100vh - 100px)"
      display="flex"
      flexDirection="column"
      paddingTop={1}
    >
      <Typography variant="h2" gutterBottom>
        Sales
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom={2}
      >
        <SaleStatusSelector
          saleStatusSelected={saleStatusSelected}
          setSaleStatusSelected={setSaleStatusSelected}
        />
        <DateRangeSelector
          customOptionRef={dateRangeCustomRef}
          dateRangeSelected={dateRangeSelected}
          setDateRangeSelected={setDateRangeSelected}
          setOpen={setOpen}
        />
        <DateRangeCustomModal
          customOptionRef={dateRangeCustomRef}
          open={open}
          setOpen={setOpen}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
        />
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {error ? (
          <Box width="100%" position="relative" mb={2}>
            <StatusChip
              type="error"
              title="Error finding address"
              message={error}
            />
          </Box>
        ) : (
          <Chart fetching={fetching} data={chartData} />
        )}
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <PriceCard
              fetching={fetching}
              title="Avg One Off Price"
              price={avgOneOffPrice}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <PriceCard
              fetching={fetching}
              title="Avg Recurring Price"
              price={avgRecurringPrice}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
