import { CheckCircle, CopyAll, Email } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Slide,
  SlideProps,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { generatePadadocLink } from "../../../api/v1/affinity/sales";
import * as SalesAPI from "../../../api/v1/affinity/sales";
import styled, { keyframes } from "styled-components";

interface ContractModalProps {
  open: boolean;
  oppName: string;
  orderId: string;
  onClose: () => void;
}

const initialLinkState = {
  status: "",
  message: "",
  code: "",
  link: "",
  expires_at: "",
};

const initialReminderState = {
  sending: false,
  status: "",
  message: "",
  code: "",
  data: {},
};

export const ContractModal = ({
  open,
  oppName,
  orderId,
  onClose,
}: ContractModalProps) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const [pandadocInfo, setPandadocInfo] = useState(initialLinkState);
  const [reminderInfo, setReminderInfo] = useState(initialReminderState);

  const pandadocMutation = useMutation<SalesAPI.PandadocLinkResponse, any>(
    async () => await generatePadadocLink(orderId),
    {
      onSuccess: (outcome) =>
        setPandadocInfo({ ...pandadocInfo, ...outcome.data, ...outcome }),
      onError: () =>
        setPandadocInfo({
          ...initialLinkState,
          message: "Failed to generate link. Please contact support.",
          code: "error",
        }),
    }
  );

  const reminderQuery = useQuery<SalesAPI.ContractReminderResponse, Error>(
    ["contractReminder"],
    async () => {
      setReminderInfo({ ...reminderInfo, sending: true });
      return await SalesAPI.sendContractReminder(orderId);
    },
    {
      onSuccess: (outcome) =>
        setReminderInfo({
          ...reminderInfo,
          ...outcome,
          sending: false,
        }),
      onError: () =>
        setReminderInfo({ ...initialReminderState, status: "Error" }),
      enabled: false,
    }
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(pandadocInfo.link);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const handleClose = () => {
    pandadocMutation.reset();
    setPandadocInfo(initialLinkState);
    setReminderInfo(initialReminderState);
    setCopied(false);
    onClose();
  };

  useEffect(() => {
    if (orderId && pandadocMutation.isIdle) pandadocMutation.mutate();
  }, [orderId, pandadocMutation]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      aria-labelledby="contract-options"
    >
      <DialogTitle id="contract-options">
        Pandadoc link for {oppName}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!pandadocInfo.code ? (
          pandadocInfo.link ? (
            <Grid
              container
              alignItems="center"
              spacing={1}
              className={classes.content}
            >
              <Grid item>
                <Link
                  href={pandadocInfo.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {pandadocInfo.link}
                </Link>
              </Grid>
              <Grid item xl={1} lg={2} md={1} sm={1} xs={1}>
                {!copied ? (
                  <CopyAll
                    color="primary"
                    onClick={handleCopy}
                    sx={{ cursor: "pointer" }}
                  />
                ) : (
                  <ShowHideAnimation in={copied}>
                    <CheckCircle color="primary" />
                  </ShowHideAnimation>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              alignItems="center"
              spacing={1}
              className={classes.content}
            >
              <Grid item>
                <CircularProgress size={25} />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Generating, please wait...
                </Typography>
              </Grid>
            </Grid>
          )
        ) : (
          <Alert className={classes.content} severity="error">
            {pandadocInfo.message}
          </Alert>
        )}
        <Button
          color="primary"
          variant="contained"
          className={reminderInfo.status && classes.content}
          disabled={reminderInfo.sending || Boolean(reminderInfo.status)}
          startIcon={
            reminderInfo.sending ? <CircularProgress size={20} /> : <Email />
          }
          onClick={() => reminderQuery.refetch()}
        >
          Send contract reminder
        </Button>
        {/* <Box className={classes.alertBox}> */}
        {reminderInfo.status && (
          <ShowHideAnimation in={Boolean(reminderInfo.status)}>
            <Alert
              severity={reminderInfo.message ? "error" : "success"}
              className={classes.alert}
            >
              {reminderInfo.message || "PandaDoc reminder sent!"}
            </Alert>
          </ShowHideAnimation>
        )}
        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {
      minWidth: 500,
      paddingBottom: 0,
    },
    alertBox: {
      height: 18,
    },
    alert: {
      padding: 0,
    },
    content: {
      marginBottom: theme.spacing(1),
    },
  })
);

const fadeIn = keyframes`
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
`;

const ShowHideAnimation = styled.div<{ in: boolean }>`
  display: ${(props) => (props.in ? "block" : "none")};
  animation: ${(props) => (props.in ? fadeIn : "")} 0.5s linear;
`;
