import { kongAxios } from "../../../core/kongAxios";

// See DC: lib/SelfService/ExternalServices/v1/Affinity/Sales/Quotes.pm

export enum SortFields {
  quoteId = "quoteId",
  customerName = "customerName",
  customerEmail = "customerEmail",
  customerReference = "customerReference",
  endCustomerAccountNo = "endCustomerAccountNo",
  createdByName = "createdByName",
  endCustomerAccount = "endCustomerAccount",
  createdAtTimestamp = "createdAtTimestamp",
  value = "value",
  displayName = "displayName",
  totalCount = "totalCount",
  opportunityCount = "opportunityCount",
  opportunityName = "opportunityName",
  companyName = "companyName",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

enum AllowMultipleQuotesParams {
  quoteId = "quoteId",
  customerName = "customerName",
  customerEmail = "customerEmail",
  createdByName = "createdByName",
  companyName = "companyName",
  endCustomerAccountNo = "endCustomerAccountNo",
  opportunityName = "opportunityName",
}

type QuotesParams = {
  _page: number;
  _limit: number;
  _sort: SortFields;
  _order?: SortOrder;
  order_source: string;
  // Filters:
  quoteId?: string;
  quoteId_in?: string /* comma separated quote id */;
  customerName?: string;
  customerName_in?: string /* comma separated names */;
  customerEmail?: string;
  customerEmail_in?: string /* comma separated emails */;
  customerReference?: string;
  endCustomerAccountNo?: string;
  endCustomerAccountNo_in?: string /* comma separated company refs */;
  createdByName?: string;
  createdByName_in?: string /* comma separated names */;
  endCustomerAccount?: string;
  createdAtTimestamp?: string;
  status?: string;
  status_in?: string /* comma separated statuses */;
  opportunityCount?: string;
  companyName?: string;
  companyName_in?: string /* comma separated company name */;
  // Order only?:
  opportunityName?: string;
  opportunityName_in?: string /* comma separated op name */;
  customerUUID?: string;
};

export type QuotesResult = {
  oneOffPrice: string;
  createdByName: string;
  createdAtTimestamp: number;
  endCustomerAccountNo: string;
  customerReference: string;
  opportunityName: string;
  quoteId: string;
  recurringPrice: string;
  opportunityCount: number;
  customerName: string;
  pdfId: null | string;
  customerEmail: string;
  status: QuoteStatus;
  companyName: string;
};

export enum QuoteStatus {
  lead = "lead",
  draft = "draft",
  approval = "approval",
  internal_approval = "internal_approval",
  sent = "sent",
  viewed = "viewed",
  completed = "completed",
  expired = "expired",
  declined = "declined",
  cancelled = "cancelled",
  rejected = "rejected",
}

export type ResponseMeta = {
  start: number;
  totalPages: number;
  lastPage: string;
  end: number;
  limit: number;
  firstPage: null | string;
  nextPage: string;
  totalCount: number;
  page: number;
  prevPage: null | string;
};

export interface QuotesResponse200 {
  results: QuotesResult[];
  meta: ResponseMeta;
}

export const quotes = (params: QuotesParams) => {
  /** other filter params are 1:1 with return properties, but status_in is an exception which allows multiple values,
   * so is changed here*/
  let p = { ...params };
  if (p.status !== "lead") {
    p.status_in = p.status;
    delete p.status;
  }
  for (const field of Object.values(AllowMultipleQuotesParams)) {
    if (p[field]) {
      p[`${field}_in`] = p[field];
      delete p[field];
    }
  }
  return kongAxios
    .get<QuotesResponse200>("ExternalServices/v1/Affinity/Sales/Quotes", {
      params: p,
    })
    .then((res) => res.data);
};

export const quotesCSV = (params: QuotesParams) => {
  let p = { ...params };
  if (p.status !== "lead") {
    p.status_in = p.status;
    delete p.status;
  }
  for (const field of Object.values(AllowMultipleQuotesParams)) {
    if (p[field]) {
      p[`${field}_in`] = p[field];
      delete p[field];
    }
  }
  return kongAxios
    .get("ExternalServices/v1/Affinity/Sales/Quotes/CSV", {
      params: p,
    })
    .then((res) => res.data);
};

export interface QuotesStatusesResponse200 {
  lead: number;
  draft: number;
  declined: number;
  cancelled: number;
  expired: number;
  approval: number;
  internal_approval: number;
  viewed: number;
  sent: number;
  completed: number;
  rejected: number;
}

export const quotesStatuses = (params: Partial<QuotesParams>) =>
  kongAxios
    .get<QuotesStatusesResponse200>(
      "ExternalServices/v1/Affinity/Sales/Quotes/Statuses",
      {
        params,
      }
    )
    .then((res) => res.data);

export type QuotesOptionsParams = {
  filters?: string;
};

export type QuotesOption = {
  displayName: string;
  totalCount: string;
  value: string;
};
export type QuotesOptionsResponse200 = QuotesOption[];

export const quotesOptions = (
  option: SortFields, // TODO: Maybe not 100% match? DateRange on current w/different shape
  params: QuotesOptionsParams
) =>
  kongAxios
    .get<QuotesOptionsResponse200>(
      `ExternalServices/v1/Affinity/Sales/Quotes/Options/${option}`,
      { params }
    )
    .then((res) => res.data);

// TODO: refactor this - moved from Pete's old code + it has too much logic for here
export const SalesStatusPdfBlob = (url: string) => {
  return kongAxios("ExternalServices/v1/Affinity/Sales/Quotes/PDF/" + url, {
    headers: {
      pragma: "no-cache",
      "cache-control": "no-cache",
    },
    responseType: "blob",
  }).then((response) => {
    let filename;
    const disposition =
      response.headers && response.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /^attachment; filename="(.+)"$/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1];
      }
    }

    return {
      filename: filename,
      file: response,
    };
  });
};

export type ContractReminderResponse = {
  status: string;
  data?: {};
  message?: string;
  code?: string;
};

export const sendContractReminder = async (orderId: string) => {
  return await kongAxios
    .post<ContractReminderResponse>(
      `ExternalServices/v1/Orders/SendPandaDocReminder/${orderId}`
    )
    .then((response) => response.data);
};

export interface PandadocLinkResponse {
  status: string;
  data?: {
    link: string;
    expires_at: string;
  };
  message?: string;
  code?: string;
}

export const generatePadadocLink = async (orderId: string) => {
  return await kongAxios
    .get<PandadocLinkResponse>(
      `ExternalServices/v1/Orders/PandaDocContractLink/${orderId}`
    )
    .then((response) => response.data);
};
