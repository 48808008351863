import { kongAxios, objectToFormData } from "../../core/kongAxios";
import {
  AccountSettingsResponse,
  LeadCreateParams,
  LeadCreateResponse,
} from "../../shared/types/accounts";

// Get account settings
export const AccountSettings = () =>
  kongAxios
    .get<AccountSettingsResponse>("ExternalServices/v1/PlatformSettings?")
    .then((response) => response.data.settings);

/**
 * Create a new lead (aka Prospect)
 */
export const LeadCreate = (params: LeadCreateParams) =>
  kongAxios.post<LeadCreateResponse>(
    "ExternalServices/v1/Accounts/Leads/Create",
    objectToFormData(params)
  );
