import { format, parse, parseISO } from "date-fns";

// packages/affinity-hub-fe/src/utils/format.ts
// Also in BillLimit & SimSwitchResults
export const formatCurrency = (
  val: string | number,
  locales: string | string[] = "en-GB",
  options: object = {}
) => {
  const parsed = typeof val === "number" ? val : parseFloat(val);
  const formatter = new Intl.NumberFormat(locales, {
    style: "currency",
    currency: "GBP",
    ...options,
  });
  return formatter.format(parsed);
};

// packages/affinity-hub-fe/src/utils/format.ts
// only usage is in formatCurrency
export const formatDateToMonthYear = (date: string | undefined) => {
  try {
    const isUnbilled = getUnbilledPeriodDate(date);
    if (isUnbilled) {
      return isUnbilled;
    } else {
      return date ? format(parseISO(date), "MMMM yyyy") : date;
    }
  } catch (error) {
    return date;
  }
};
// Only usage in formatDateToMonthYear
const getUnbilledPeriodDate = (date: string | undefined) => {
  if (date?.startsWith("UNBILLED")) {
    const unbilled = date?.split("-") || [];
    if (unbilled?.length > 1 && unbilled) {
      const period = format(parseISO(unbilled?.[1]), "MMMM yyyy");
      return `${unbilled?.[0]} (${period})`;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

// Lots of usages. copy
export const formatDateWithPattern = (
  date: string | undefined,
  pattern: string
) => {
  return date ? format(parseISO(date), pattern) : "";
};

// Only in filter
export const parseStringToDate = (date: string, pattern: string) => {
  return date ? parse(date, pattern, new Date()) : null;
};

// A few usages. copy
export function isEmpty(val: any) {
  if (typeof val === "number") return false;
  if (Array.isArray(val)) return !val.length;
  return !val;
}

// dummy: packages/affinity-hub-fe/src/api/filter.ts
// The ajax API fn used by fetchResourcesHook in full implementation, but also direct in IdentifierFilter
export const getFilterValues = async (
  resource: string | undefined,
  offset?: number,
  search?: string
) => {
  return {
    list: [""],
    page: 0,
    pageSize: 20,
    total: 0,
  };
};

export const formatCostCentre = (code: string | undefined) => {
  return code ? code.replaceAll(";", " | ") : "";
};
