import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  format,
  getUnixTime,
  startOfDay,
  startOfHour,
  subDays,
} from "date-fns";
import { AccountSettings, getErrorMessage } from "@akj-dev/affinity-platform";
import { DashboardData, DashboardDataResponse, ErrorResponse } from "./types";
import { getDashboardData } from "./api";
import { DateRangeEnum } from "./date-range/enums";
import { SaleStatusEnum } from "./sale-status/enums";

export const useHandleFromTo = (
  range: DateRangeEnum,
  setFrom: Dispatch<SetStateAction<Date>>,
  setTo: Dispatch<SetStateAction<Date>>
) => {
  useEffect(() => {
    const now = new Date();
    switch (range) {
      case DateRangeEnum.DAY: {
        const from = subDays(startOfHour(now), 1);
        const to = now;

        setFrom(from);
        setTo(to);
        break;
      }
      case DateRangeEnum.CUSTOM:
      case DateRangeEnum.WEEK: {
        const from = startOfDay(subDays(now, 7));
        const to = now;

        setFrom(from);
        setTo(to);
        break;
      }
      case DateRangeEnum.MONTH: {
        const from = startOfDay(subDays(now, 30));
        const to = now;

        setFrom(from);
        setTo(to);
        break;
      }
    }
  }, [range, setFrom, setTo]);
};

export interface FetchDashboardDataProps {
  dateRangeSelected: DateRangeEnum;
  saleStatusSelected: SaleStatusEnum;
  from: Date;
  to: Date;
}

export const useFetchDashboardData = ({
  dateRangeSelected,
  saleStatusSelected,
  from,
  to,
}: FetchDashboardDataProps) => {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState<DashboardData>({
    chartData: [],
    avgOneOffPrice: "",
    avgRecurringPrice: "",
  });
  const offset = new Date().getTimezoneOffset();
  const fromUnixTime = getUnixTime(from);
  const toUnixTime = getUnixTime(to);

  useEffect(() => {
    setFetching(true);

    const interval = (dateRangeSelected === DateRangeEnum.DAY ? 1 : 24) * 60;

    async function fetchData() {
      setError("");
      try {
        const response = await getDashboardData({
          fromUnixTime,
          toUnixTime,
          status: saleStatusSelected,
          interval,
        });
        const errorResponse = response as ErrorResponse;
        const successResponse = response as DashboardDataResponse;

        if (errorResponse.status === "error") {
          setError(errorResponse.message);
        } else {
          const { avgOneOffPrice, avgRecurringPrice, timestamps } =
            successResponse;

          // preprocess data
          const dashboardData: DashboardData = {
            avgOneOffPrice: parseFloat(avgOneOffPrice || "0").toFixed(2),
            avgRecurringPrice: parseFloat(avgRecurringPrice || "0").toFixed(2),
            chartData: timestamps.map((dataPoint: any) => {
              const timestamp = dataPoint.timestamp - offset * 60;
              return {
                ...dataPoint,
                timestamp,
                label: prettifyTimeStamp(timestamp, dateRangeSelected),
                saleStatusSelected,
              };
            }),
          };

          setData(dashboardData);
        }
      } catch (e) {
        setError(getErrorMessage(e));
        console.error(e);
      }
      setFetching(false);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleStatusSelected, fromUnixTime, toUnixTime]);

  return { fetching, error, data };
};

const prettifyTimeStamp = (timeStamp: number, range: DateRangeEnum) => {
  const milliseconds = timeStamp * 1000;

  switch (range) {
    case DateRangeEnum.DAY:
      return format(milliseconds, "h aaa");
    case DateRangeEnum.WEEK:
      return format(milliseconds, "EEE");
    default:
      return format(milliseconds, "d/L");
  }
};

/**
 * return visible sale statuses based on the account settings
 * @param accountSettings
 */
export const useSaleStatuses = (accountSettings: AccountSettings) =>
  useMemo(() => {
    return [
      {
        id: SaleStatusEnum.PROSPECTS,
        label: "Prospects",
        visible: accountSettings.dws_reseller_enabled === "0",
      },
      {
        id: SaleStatusEnum.OPPORTUNITIES,
        label: "Opportunities",
        visible: true,
      },
      {
        id: SaleStatusEnum.APPROVALS,
        label: "Approvals",
        visible:
          accountSettings.requires_external_approval_for_external_user_orders ===
          "1",
      },
      {
        id: SaleStatusEnum.SUPPLIER_APPROVALS,
        label: "Supplier Approvals",
        visible: accountSettings.as_supplier_approvals_enabled === "1",
      },
      { id: SaleStatusEnum.SENT, label: "Sent", visible: true },
      { id: SaleStatusEnum.VIEWED, label: "Viewed", visible: true },
      { id: SaleStatusEnum.COMPLETED, label: "Completed", visible: true },
      { id: SaleStatusEnum.EXPIRED, label: "Expired", visible: true },
      { id: SaleStatusEnum.DECLINED, label: "Declined", visible: true },
      { id: SaleStatusEnum.CANCELLED, label: "Deleted", visible: true },
    ].filter(({ visible }) => visible);
  }, [accountSettings]);
