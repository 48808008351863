export enum DateRangeEnum {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  CUSTOM = "CUSTOM",
}

export const dateRanges = [
  {
    id: DateRangeEnum.DAY,
    label: "Last 24 Hours",
  },
  {
    id: DateRangeEnum.WEEK,
    label: "Last 7 Days",
  },
  {
    id: DateRangeEnum.MONTH,
    label: "Last 30 Days",
  },
  {
    id: DateRangeEnum.CUSTOM,
    label: "Custom",
  },
];
