import { useInfiniteQuery } from "react-query";
import { FilterValues } from "@akj-dev/shared-components";
import * as AffinitySalesAPI from "../../api/v1/affinity/sales";

export const useSalesStatusFilterValues = (
  resource: string | undefined,
  // This endpoint doesn't have pagination or filtering yet, but these params
  // would be used when it does as part of the existing hub-fe Filter component
  search: string = "",
  options: object
) => {
  return useInfiniteQuery<FilterValues, any>(
    ["sales-status-filter-values", resource],
    async () => {
      const response = await AffinitySalesAPI.quotesOptions(
        resource as any,
        {}
      );
      return {
        list: response.map((el) => el.value),
        page: 0,
        pageSize: response.length,
        total: response.length,
      };
    },
    {}
  );
};
