import React from "react";
import { Box, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";

interface QuoteIdLinkProps {
  quoteId: string;
  to?: string;
  href?: string;
  title: string;
}

export const QuoteIdLink = ({ quoteId, to, href, title }: QuoteIdLinkProps) => {
  const classes = useStyles();
  const inner = (
    <Tooltip title={title} arrow placement="right">
      <Box className={classes.inner}>
        <Box className={classes.text}>{quoteId}</Box>
        <LaunchIcon color="inherit" fontSize="inherit" />
      </Box>
    </Tooltip>
  );
  if (to)
    return (
      <Link to={to} className={classes.root}>
        {inner}
      </Link>
    );

  if (href)
    return (
      <a href={href} className={classes.root}>
        {inner}
      </a>
    );

  return <>quoteId</>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,

    textDecoration: "none",
  },
  inner: { display: "flex", alignItems: "center" },
  text: {
    marginRight: 4,
  },
}));
