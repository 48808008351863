import React from "react";
import { format } from "date-fns";
import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ChartData } from "../types";
import { SaleStatusLabelEnum } from "../sale-status/enums";

interface ChartTooltipProps {
  active: boolean;
  payload: [{ payload: ChartData }];
}

export const ChartTooltip = ({ active, payload }: ChartTooltipProps) => {
  if (active && payload && payload.length) {
    const { count, timestamp, saleStatusSelected } = payload[0].payload;

    const label = format(timestamp * 1000, "eee d MMMM yyyy");
    const value = `${count} ${SaleStatusLabelEnum[saleStatusSelected]}`;

    return (
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "8px 16px",
        }}
      >
        <Typography variant="body1">{label}</Typography>
        <Typography color="primary" variant="body1">
          {value}
        </Typography>
      </Card>
    );
  }

  return null;
};
