import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { QuoteStatus } from "../../../api/v1/affinity/sales";
import { SvgIconComponent } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import clsx from "clsx";

interface CountCardProps {
  label: string;
  quoteStatus: QuoteStatus | "ALL";
  count?: number;
  loading: boolean;
  Icon?: SvgIconComponent;
}

export const CountCard = ({
  label,
  quoteStatus,
  count,
  Icon,
  loading,
}: CountCardProps) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>(null);
  const [wrapped, setWrapped] = useState(false);

  const currentStatus =
    new URLSearchParams(location.search).get("status")?.split(",") || [];

  const active =
    currentStatus.includes(quoteStatus) ||
    (currentStatus.length === 0 && quoteStatus === "ALL");

  const handleNav = () => {
    history.push(
      `${match.url}${quoteStatus !== "ALL" ? `?status=${quoteStatus}` : ``}`
    );
  };

  // We can assume text is wrapped if Header exceeds minimum height
  const evaluateWrapping = () => setWrapped(ref.current?.scrollHeight !== 61);

  useEffect(() => {
    evaluateWrapping();
    window.addEventListener("resize", evaluateWrapping);
  }, []);

  return (
    <Grid item md={3} lg={2}>
      <Card
        onClick={handleNav}
        className={clsx(
          classes.card,
          active && classes.active,
          wrapped && classes.wrapped
        )}
        elevation={2}
      >
        <CardHeader
          ref={ref}
          avatar={Icon && <Icon color="primary" />}
          title={label}
        />
        <CardContent>
          {loading ? (
            <Skeleton variant="circular" width={23} height={23} />
          ) : (
            <Typography variant="h3">{count}</Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: "100%",
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
    transition: ".2s ease-in-out",
    "&:hover": {
      transform: "translateY(-8px)",
      background: theme.palette.primary.light,
    },
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    marginTop: -1,
    "& .MuiTypography-root": {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
  },
  // Realign icon and reduce padding if wrapped
  wrapped: {
    "& .MuiCardHeader-root": {
      alignItems: "flex-start",
    },
    "& .MuiCardContent-root": {
      paddingTop: 4,
    },
  },
}));
