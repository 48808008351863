import React from "react";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";

interface DeleteButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export const DeleteButton = ({ onClick, disabled }: DeleteButtonProps) => {
  if (disabled) return null;
  return (
    <IconButton onClick={onClick} size="large">
      <Cancel />
    </IconButton>
  );
};
