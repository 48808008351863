import React from "react";
import { IconButton } from "@mui/material";
import { Assignment } from "@mui/icons-material";

type ContractButtonProps = {
  onClick: () => void;
};

export const ContractButton = ({ onClick }: ContractButtonProps) => (
  <IconButton onClick={onClick} size="large">
    <Assignment />
  </IconButton>
);
