import React, { Dispatch, SetStateAction, useState } from "react";
import { endOfDay, format, startOfDay } from "date-fns";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Grid,
  Popper,
  TextField,
} from "@mui/material";
import { DC_DATE_FORMAT } from "../../../shared/utils/date";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export interface DateRangeCustomModalProps {
  customOptionRef: React.RefObject<any>;
  open: boolean;
  from: Date;
  to: Date;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setFrom: Dispatch<SetStateAction<Date>>;
  setTo: Dispatch<SetStateAction<Date>>;
}

export const DateRangeCustomModal = ({
  customOptionRef,
  open,
  setOpen,
  from: defaultFrom,
  setFrom: setDefaultFrom,
  to: defaultTo,
  setTo: setDefaultTo,
}: DateRangeCustomModalProps) => {
  const classes = useStyles();

  const [from, setFrom] = useState<Date>(defaultFrom);
  const [to, setTo] = useState<Date>(defaultTo);

  const handleFrom = (date: Date) => {
    if (date) {
      const newFrom = startOfDay(date);
      setFrom(newFrom);
    }
  };
  const handleTo = (date: Date) => {
    if (date) {
      const newTo = endOfDay(date);
      setTo(newTo);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setDefaultFrom(from);
    setDefaultTo(to);

    setOpen(false);
  };

  const now = new Date();
  return (
    <Popper
      open={open}
      anchorEl={customOptionRef.current}
      className={classes.popper}
      placement="bottom-end"
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Card>
          <CardHeader title="Select custom date range" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <DatePicker
                  value={format(from, DC_DATE_FORMAT)}
                  label="From"
                  inputFormat="dd/MM/yyyy"
                  onChange={(date: string | null) =>
                    handleFrom(new Date(date as string))
                  }
                  maxDate={format(to ?? now, DC_DATE_FORMAT)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <DatePicker
                  value={format(to, DC_DATE_FORMAT)}
                  label="To"
                  inputFormat="dd/MM/yyyy"
                  onChange={(date: string | null) =>
                    handleTo(new Date(date as string))
                  }
                  minDate={format(from, DC_DATE_FORMAT)}
                  maxDate={format(now, DC_DATE_FORMAT)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Apply
            </Button>
          </CardActions>
        </Card>
      </ClickAwayListener>
    </Popper>
  );
};

const useStyles = makeStyles({
  popper: {
    zIndex: 1,
  },
  actions: {
    padding: 16,
  },
});
