import React from "react";
import {
  MonetizationOn,
  EmojiPeople,
  AddCircleOutline,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";
import { createRoutingObject } from "@akj-dev/shared-components";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { Dashboard } from "./screens/dashboard";
import { NewSale } from "./screens/NewSale";
import { ProspectsScreen } from "./screens/status/ProspectsScreen";
import { SalesStatusScreen } from "./screens/status/SalesStatusScreen";

export const ROOT_PATH = "/sales";

export const getRoutes = (settings: AccountSettings) =>
  createRoutingObject(
    [
      {
        path: "/",
        title: "Dashboard",
        subject: <Dashboard />,
        icon: <DashboardIcon color="inherit" fontSize="small" />,
      },
      {
        path: "/new",
        title: "New Sale",
        subject: <NewSale />,
        icon: <AddCircleOutline color="inherit" fontSize="small" />,
      },

      {
        path: "/sales-status",
        title: "Sales Status",
        subject: <SalesStatusScreen />,
        icon: <MonetizationOn color="inherit" fontSize="small" />,
      },
      ...(settings.dws_reseller_enabled === "0"
        ? [
            {
              path: "/prospects",
              title: "Prospects",
              subject: <ProspectsScreen />,
              icon: <EmojiPeople color="inherit" fontSize="small" />,
            },
          ]
        : []),
    ],
    ROOT_PATH
  );
