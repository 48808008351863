import * as React from "react";
import { Button, Grid, List, Theme, Typography, useTheme } from "@mui/material";

export default function FilterBox({
  item,
  resetSelection,
  addSelection,
  selection,
  setSelection,
  close,
  showReset,
  disableApply,
  children,
  ...props
}: any) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <div {...props}>
      <List disablePadding>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.filterActions}
        >
          {item?.name === "Bill Period" ? (
            <span>
              <Typography variant="caption" sx={styles.filterTitle}>
                Filter by {item.name}
              </Typography>
              {item.MAX_COUNT && (
                <Typography component="p" variant="caption">
                  You can select up to {item.MAX_COUNT} bill/s
                </Typography>
              )}
            </span>
          ) : (
            <Typography variant="caption" sx={styles.filterTitle}>
              Filter by {item.name}
            </Typography>
          )}
          <Button
            data-cy="reset-filter-button"
            size="small"
            onClick={() => resetSelection()}
            sx={showReset ? styles.show : styles.hidden}
          >
            Reset
          </Button>
        </Grid>
        {children}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={styles.filterAdd}
        >
          <Button
            data-cy="add-filter-button"
            variant="contained"
            size="small"
            color="primary"
            onClick={() => addSelection()}
            disabled={disableApply ? disableApply : selection.length < 1}
          >
            Apply
          </Button>
          <Button
            data-cy="close-filter-button"
            size="small"
            onClick={() => close()}
          >
            Cancel
          </Button>
        </Grid>
      </List>
    </div>
  );
}

const getStyles = (theme: Theme) => ({
  filterActions: {
    padding: theme.spacing(2),
  },
  filterAdd: {
    padding: theme.spacing(2),
  },
  filterTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "initial",
  },
});
