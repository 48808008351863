import { kongAxios, objectToFormData } from "../../core/kongAxios";

/**
 * Generate a new order on an account or lead
 * As discussed, this call can create orders for all scenarios from a customer_uuid
 * except for business partners creating a new prospect.
 * @see https://akj-hq.slack.com/archives/GTFHJENFQ/p1607526979180000
 */
export const NewOrderForAccountOrLead = (
  params: NewOrderForAccountOrLeadParams
) =>
  kongAxios.post<NewOrderForAccountOrLeadResponse>(
    "ExternalServices/v1/GuidedSales/NewOrderForAccountOrLead",
    objectToFormData(params)
  );

type NewOrderForAccountOrLeadParams = {
  /* for lead */
  lead_id?: string;
  /* for platform graph back end */
  customer_uuid?: string;
  /* Order name - referred to as opportunity name in UI sometimes?
  ...as requested by business, although they are a different thing in DC 🤨 */
  name: string;
  /* to differentiate between Sales and Orders products */
  order_source: string;
  /* for order on existing account. Used on Affinity Orders */
  account_id?: string;
};

export type NewOrderForAccountOrLeadResponse = {
  status: string;
  message?: string;
  code?: string;

  crm_user_name: string;
  opportunity_number: string;
  opportunity_name: string;
  opportunity_id: string;
  order_id: string;
  crm_user_id: string;
  lead_id?: string;
  lead_number?: string;
  account_id?: string;
  platform_customer_reference?: string; // TP44475
  customer_name?: string; // TP22406
  lead_name?: string;
  unrestrict?: string; // TP174727
};
