/**
 * Validation helpers
 * TODO: These are duplicated in CPQ and probably elsewhere. Should we make shared package?
 */

/**
 * Valid general text (only certain special characters allowed).
 * Restriction of special characters wanted as part of a couple of tickets, including:
 * https://auroratarget.tpondemand.com/entity/11524-delivery-page-validation-checks,
 * https://auroratarget.tpondemand.com/entity/8950-validation-issues and
 * https://auroratarget.tpondemand.com/entity/11525-opportunity-name-validation-checks
 * Agreed with Toby to restrict accented characters 26/08/2020.
 *
 * @param value
 * @returns {boolean}
 */
export const isValidTextRegex = /^[A-Za-z0-9\-'., ]+$/;
