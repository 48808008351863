import React, { useState } from "react";
import download from "downloadjs";
import { LoaderButton, StatusChip } from "@akj-dev/shared-components";
import { quotesCSV } from "../../../api/v1/affinity/sales";
import { CloudDownload } from "@mui/icons-material";

interface CSVDownloadProps {
  filterParams: any;
}

export const CSVDownload = ({ filterParams }: CSVDownloadProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCSVDownload = async () => {
    setLoading(true);
    try {
      const response = await quotesCSV(filterParams);
      download(response, "sales-status.csv");
    } catch (e) {
      // @ts-ignore
      setError(e.message);
    }
    setLoading(false);
  };

  if (error) return <StatusChip type="error" message={error} />;

  return (
    <LoaderButton
      loading={loading}
      onClick={handleCSVDownload}
      variant="outlined"
      color="primary"
      endIcon={<CloudDownload />}
    >
      Download CSV
    </LoaderButton>
  );
};
