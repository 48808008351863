import React from "react";
import { useQuery } from "../shared/hooks/useQuery";
import { Cpq } from "@akj-dev/cpq";
import { kongAxios } from "../core/kongAxios";

export const CpqWrapper = () => {
  const query = useQuery();

  return (
    <Cpq
      hostEnv="platform"
      fetchFn={kongAxios}
      accountId={query.get("accountId")}
      platformCustomerReference={query.get("platformCustomerReference")}
      savedOrderId={query.get("order_id")} // This is already in DC code hence stick to the snake_case
      orderId={query.get("orderId")}
      leadId={query.get("leadId")}
      leadNumber={query.get("leadNumber")}
      opportunityId={query.get("opportunityId")}
      opportunityNumber={query.get("opportunityNumber")}
      opportunityName={query.get("opportunityName")}
      customerName={query.get("customerName")}
      leadName={query.get("leadName")}
      unrestrict={query.get("unrestrict")}
    />
  );
};
