import React from "react";
import { useQuery } from "react-query";
import * as salesAPI from "../../../api/v1/affinity/sales";
import {
  QuotesStatusesResponse200,
  QuoteStatus,
} from "../../../api/v1/affinity/sales";
import { Box, Grid } from "@mui/material";
import { CountCard } from "./CountCard";
import { useAuth } from "@akj-dev/affinity-platform";
import {
  CheckCircle,
  Stars,
  Highlight,
  Email,
  Visibility,
  Warning,
  Delete,
  ThumbDown,
  ListAlt,
} from "@mui/icons-material";
import { StatusChip } from "@akj-dev/shared-components";

export const CountCards = () => {
  const { data, isLoading, error } = useQuery<QuotesStatusesResponse200, Error>(
    ["quoteStatuses"],
    () => salesAPI.quotesStatuses({})
  );
  const { accountSettings } = useAuth();

  const all =
    data &&
    Object.entries(data)
      .filter((a, c) => {
        if (a[0] === "lead") return false;
        if (
          accountSettings.requires_external_approval_for_external_user_orders !==
            "1" &&
          a[0] === "approval"
        )
          return false;
        if (
          accountSettings.as_supplier_approvals_enabled !== "1" &&
          a[0] === "internal_approval"
        )
          return false;
        if (accountSettings.can_access_vf_direct === "1" && a[0] === "rejected")
          return false;
        return true;
      })
      .reduce((a, c) => {
        return a + c[1];
      }, 0);

  if (error)
    return (
      <Box mb={2}>
        <StatusChip type="error" message={error.message} />
      </Box>
    );

  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <CountCard
          label="All"
          quoteStatus="ALL"
          count={all}
          loading={isLoading}
          Icon={Highlight}
        />
        <CountCard
          label="Opportunities"
          quoteStatus={QuoteStatus.draft}
          count={data?.draft}
          loading={isLoading}
          Icon={ListAlt}
        />
        {accountSettings.requires_external_approval_for_external_user_orders ===
          "1" && (
          <CountCard
            label="Approvals"
            quoteStatus={QuoteStatus.approval}
            count={data?.approval}
            loading={isLoading}
            Icon={Stars}
          />
        )}
        {accountSettings.as_supplier_approvals_enabled === "1" && (
          <CountCard
            label="Supplier Approvals"
            quoteStatus={QuoteStatus.internal_approval}
            count={data?.internal_approval}
            loading={isLoading}
            Icon={Stars}
          />
        )}
        <CountCard
          label="Sent"
          quoteStatus={QuoteStatus.sent}
          count={data?.sent}
          loading={isLoading}
          Icon={Email}
        />
        <CountCard
          label="Viewed"
          quoteStatus={QuoteStatus.viewed}
          count={data?.viewed}
          loading={isLoading}
          Icon={Visibility}
        />
        <CountCard
          label="Completed"
          quoteStatus={QuoteStatus.completed}
          count={data?.completed}
          loading={isLoading}
          Icon={CheckCircle}
        />
        <CountCard
          label="Expired"
          quoteStatus={QuoteStatus.expired}
          count={data?.expired}
          loading={isLoading}
          Icon={Warning}
        />
        <CountCard
          label="Declined"
          quoteStatus={QuoteStatus.declined}
          count={data?.declined}
          loading={isLoading}
          Icon={ThumbDown}
        />
        <CountCard
          label="Deleted"
          quoteStatus={QuoteStatus.cancelled}
          count={data?.cancelled}
          loading={isLoading}
          Icon={Delete}
        />
        {accountSettings.can_access_vf_direct !== "1" && (
          <CountCard
            label="Rejected"
            quoteStatus={QuoteStatus.rejected}
            count={data?.rejected || 0}
            loading={isLoading}
            Icon={ThumbDown}
          />
        )}
      </Grid>
    </Box>
  );
};
