import { QuotesResult, QuoteStatus } from "../../../api/v1/affinity/sales";
import { FilterType } from "@akj-dev/shared-components";

export const COLUMN_NAMES: {
  [key in keyof QuotesResult | "contract" | "delete"]: string;
} = {
  quoteId: "ID",
  customerEmail: "Email",
  opportunityName: "Opportunity Name",
  customerName: "Contact Name",
  endCustomerAccountNo: "Company Reference",
  opportunityCount: "No. of Opportunities",
  createdAtTimestamp: "Date & Time",
  createdByName: "Created By",
  customerReference: "", // unused
  oneOffPrice: "One off price",
  recurringPrice: "Recurring Price",
  pdfId: "Download",
  status: "Status",
  contract: "Contract",
  delete: "Delete",
  companyName: "Company Name",
};

export const COLUMN_FILTER_TYPES: {
  [key in keyof QuotesResult | "contract" | "delete"]?: FilterType;
} = {
  quoteId: FilterType.AUTO_TEXT,
  status: FilterType.ENUM,
  customerEmail: FilterType.AUTO_TEXT,
  opportunityName: FilterType.AUTO_TEXT,
  customerName: FilterType.AUTO_TEXT,
  endCustomerAccountNo: FilterType.AUTO_TEXT,
  createdAtTimestamp: FilterType.DATE_RANGE,
  createdByName: FilterType.AUTO_TEXT,
  companyName: FilterType.AUTO_TEXT,
};

export const QUOTE_STATUS_NAMES: {
  [key in QuoteStatus]: string;
} = {
  lead: "Prospect",
  draft: "Opportunity",
  approval: "Approval",
  internal_approval: "Supplier Approval",
  sent: "Sent",
  viewed: "Viewed",
  completed: "Completed",
  expired: "Expired",
  declined: "Declined",
  cancelled: "Deleted",
  rejected: "Rejected",
};
