import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { IconButton } from "@mui/material";
import download from "downloadjs";
import { SalesStatusPdfBlob } from "../../../api/v1/affinity/sales";

interface PDFDownloadProps {
  pdfId: string | null;
  filename: string;
}

export const PDFDownload = ({ pdfId, filename }: PDFDownloadProps) => {
  if (!pdfId) return null;
  return (
    <IconButton
      aria-label="delete"
      onClick={async () => {
        try {
          const response = await SalesStatusPdfBlob(pdfId);
          const blob = await response.file.data;

          let _filename;
          if (!response.filename) {
            _filename = filename || "untitled";
          } else {
            _filename = response.filename;
          }

          download(blob, _filename, blob.type);
        } catch (e) {
          console.error("error attempting to download pdf " + pdfId, e);
        }
      }}
      size="large"
    >
      <CloudDownloadIcon fontSize="small" color="inherit" />
    </IconButton>
  );
};
