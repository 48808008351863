import React, { useState } from "react";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { TextField } from "formik-mui";
import { LoaderButton, StatusChip } from "@akj-dev/shared-components";
import { Customer } from "./api/searchCustomers";
import { NewOrderForAccountOrLead } from "../../api/v1/guidedSales";
import { useHistory, useRouteMatch } from "react-router-dom";
import { orderToCpqParams } from "./utils/orderToCpqParams";
import { getErrorMessage } from "@akj-dev/affinity-platform";

interface NewOpportunityProps {
  titlePrefix?: string;
  customer?: Customer;
}

interface FormValues {
  orderName: string;
}

export const NewOpportunity = ({
  titlePrefix = "New Opportunity for",
  customer,
}: NewOpportunityProps) => {
  const history = useHistory();
  let { url } = useRouteMatch();
  const [error, setError] = useState("");

  const validationSchema = yup.object().shape({
    orderName: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
  });

  const initialValues: FormValues = { orderName: "" };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    if (customer) {
      try {
        const response = await NewOrderForAccountOrLead({
          customer_uuid: customer.customer_uuid,
          name: values.orderName,
          order_source: "Affinity Sales",
        });
        if (response.data.status === "success") {
          const params = orderToCpqParams(response.data);
          history.push(`${url}/cpq?${params}`);
        } else {
          setError(response.data.message || "Error creating order");
        }
      } catch (e) {
        setError(getErrorMessage(e));
      }
    }
    setSubmitting(false);
  };

  return (
    <Card>
      <CardHeader title={`${titlePrefix} ${customer?.name}`} />
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                name="orderName"
                label="Opportunity Name"
              />
              <Box mt={1}>
                <LoaderButton
                  color="primary"
                  loading={isSubmitting}
                  onClick={submitForm}
                >
                  Create {customer?.status === "Prospect" ? "Quote" : "Sale"}
                </LoaderButton>
              </Box>
            </Form>
          )}
        </Formik>
        {error && (
          <Box my={1}>
            <StatusChip
              type="error"
              title="Error creating order"
              message={error}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
